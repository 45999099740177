"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveEndpointId = exports.UpdateEndpointException = void 0;
const tslib_1 = require("tslib");
tslib_1.__exportStar(require("./apis"), exports);
var types_1 = require("./types");
Object.defineProperty(exports, "UpdateEndpointException", { enumerable: true, get: function () { return types_1.UpdateEndpointException; } });
var utils_1 = require("./utils");
Object.defineProperty(exports, "resolveEndpointId", { enumerable: true, get: function () { return utils_1.resolveEndpointId; } });
